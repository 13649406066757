"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DbConnection = exports.LatencyTest = exports.SubscriptionManager = exports.EventListener = void 0;
class EventListener {
    constructor() {
        this.listeners = [];
    }
    addListener(listener) {
        this.listeners.push(listener);
    }
    removeListener(listener) {
        this.listeners = this.listeners.filter(l => l !== listener);
    }
    dispatch(event) {
        this.listeners.forEach(l => l(event));
    }
}
exports.EventListener = EventListener;
class SubscriptionManager {
    constructor() {
        this.subscriptions = new Map();
    }
    subscribe(subject, listener) {
        const key = JSON.stringify(subject);
        if (!this.subscriptions.has(key)) {
            this.subscriptions.set(key, new EventListener());
        }
        const subscription = this.subscriptions.get(key);
        subscription.addListener(listener);
    }
    unsubscribe(subject, listener) {
        const key = JSON.stringify(subject);
        if (!this.subscriptions.has(key)) {
            return;
        }
        const subscription = this.subscriptions.get(key);
        subscription.removeListener(listener);
    }
    dispatch(subject, event) {
        const key = JSON.stringify(subject);
        if (!this.subscriptions.has(key)) {
            return;
        }
        const subscription = this.subscriptions.get(key);
        subscription.dispatch(event);
    }
}
exports.SubscriptionManager = SubscriptionManager;
class LatencyTest {
    constructor() {
        this.endTime = null;
        this.startTime = performance.now();
        this.signal = new Promise((resolve) => {
            this.resolve = resolve;
        });
    }
    receivedResponse() {
        this.endTime = performance.now();
        this.resolve();
    }
    async result() {
        await this.signal;
        return this.endTime - this.startTime;
    }
}
exports.LatencyTest = LatencyTest;
class DbConnection {
    constructor() {
        this.connection = null;
        this.status = { connected: false };
        this.statusListener = new EventListener();
        this.messageListener = new EventListener();
        this.subscriptions = new SubscriptionManager();
        this.sizeSubscriptions = new SubscriptionManager();
        this.queue = [];
        this.dbUrl = null;
        this.reconnectLoopHandle = null;
        this.activeLatencyTest = null;
    }
    connect(dbUrl) {
        this.dbUrl = dbUrl;
        if (this.connection) {
            this.connection.close();
        }
        this.connection = new WebSocket(dbUrl);
        this.connection.onopen = () => {
            if (this.reconnectLoopHandle) {
                window.clearInterval(this.reconnectLoopHandle);
                this.reconnectLoopHandle = null;
            }
            this.setStatus(true);
            this.queue.forEach(message => {
                this.send(message);
            });
            this.queue = [];
        };
        this.connection.onerror = (err) => {
            console.error("DriftDB connection error", err);
            this.setStatus(false);
        };
        this.connection.onclose = () => {
            this.setStatus(false);
            console.log("Connection closed, attempting to reconnect...");
            this.reconnectLoopHandle = window.setTimeout(() => {
                this.connect(dbUrl);
            }, 1000);
        };
        this.connection.onmessage = (event) => {
            const message = JSON.parse(event.data);
            this.messageListener.dispatch(message);
            switch (message.type) {
                case 'init':
                    message.data.forEach((value) => {
                        this.subscriptions.dispatch(message.key, value);
                    });
                    break;
                case 'push':
                    this.subscriptions.dispatch(message.key, {
                        seq: message.seq,
                        value: message.value,
                    });
                    break;
                case 'stream_size':
                    this.sizeSubscriptions.dispatch(message.key, message.size);
                    break;
                case 'pong':
                    if (this.activeLatencyTest) {
                        this.activeLatencyTest.receivedResponse();
                        this.activeLatencyTest = null;
                    }
                    break;
            }
        };
    }
    testLatency() {
        if (!this.status.connected || this.connection?.readyState !== WebSocket.OPEN) {
            return null;
        }
        if (!this.activeLatencyTest) {
            this.activeLatencyTest = new LatencyTest();
            this.send({ type: 'ping' });
        }
        return this.activeLatencyTest.result();
    }
    debugUrl() {
        if (!this.dbUrl) {
            return null;
        }
        return `https://ui.driftdb.com/?url=${encodeURIComponent(this.dbUrl)}`;
    }
    disconnect() {
        console.log('disconnecting');
        this.connection?.close();
    }
    setStatus(connected) {
        this.status = connected ? { connected: true, debugUrl: this.debugUrl() } : { connected: false };
        this.statusListener.dispatch(this.status);
    }
    send(message) {
        if (!this.status.connected || this.connection?.readyState !== WebSocket.OPEN) {
            this.queue.push(message);
            return;
        }
        this.connection.send(JSON.stringify(message));
    }
    subscribe(key, listener, sizeCallback) {
        this.subscriptions.subscribe(key, listener);
        if (sizeCallback) {
            this.sizeSubscriptions.subscribe(key, sizeCallback);
        }
        this.send({ type: 'get', key, seq: 0 });
    }
    unsubscribe(subject, listener, sizeCallback) {
        this.subscriptions.unsubscribe(subject, listener);
        if (sizeCallback) {
            this.sizeSubscriptions.unsubscribe(subject, sizeCallback);
        }
    }
}
exports.DbConnection = DbConnection;
